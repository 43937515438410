import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";

import TabItem from "./TabItems";

interface TabProps {
  className?: string;
  children: React.ReactNode;
}

interface TabComposition {
  TabItem: typeof TabItem;
}

const GalleryCategoryTab: FunctionComponent<TabProps> & TabComposition = ({
  className,
  children,
}) => {
  return (
    <div className={clsMerge("flex h-[61px] items-center bg-white", className)}>
      <div className="scrollbar-hide flex h-full flex-auto items-center justify-center gap-x-[80px] overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

GalleryCategoryTab.TabItem = TabItem;

export default GalleryCategoryTab;
