import { clsMerge } from "@artifactlabs/shared-react-utils";
import { motion } from "framer-motion";

interface TabItemProps {
  current: boolean;
  handleClick: () => void;
  children: React.ReactNode;
}

const TabItem = ({ current, handleClick, children }: TabItemProps) => {
  return (
    <button
      className={clsMerge(
        "relative flex-shrink-0 py-3 text-base font-medium uppercase leading-[24px] tracking-wider text-black",
      )}
      onClick={handleClick}
    >
      {children}
      {current && (
        <motion.div
          className="absolute bottom-[0px] h-[4px] w-full bg-black"
          layoutId="grid.header.underline"
        />
      )}
    </button>
  );
};

export default TabItem;
