import { FunctionComponent, HTMLAttributes, useRef } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { GridImageItem } from "./GridImageItem";

import AppLink from "@/components/AppLink";
import Spinner from "@/components/Spinner";
import { SearchArtworksQuery } from "@/gql/graphql";
import { getAssetContentTypeWithAssetFileFormat } from "@/utils/helpers/assetContentType";
import { routes } from "@/utils/routes";

interface Props extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  assets: SearchArtworksQuery["searchArtworks"];
}

export const MasonryGallery: FunctionComponent<Props> = ({ assets, loading, ...rest }) => {
  const videoRef: any = useRef(null);

  const handleMouseOver = () => {
    if (videoRef?.current) {
      videoRef.current.play();
    }
  };

  const handleMouseOut = () => {
    if (videoRef?.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div {...rest}>
      {loading ? (
        <div className="flex flex-1 items-center justify-center pt-6">
          <Spinner />
        </div>
      ) : (
        <>
          {(assets?.paginatedResults ?? []).length > 0 && (
            <>
              <ResponsiveMasonry
                className="w-full pt-4"
                columnsCountBreakPoints={{ 640: 2, 768: 2, 1023: 3, 1280: 4 }}
              >
                <Masonry gutter={"16px"}>
                  {assets?.paginatedResults?.map((each: any) => {
                    if (!each) {
                      return null;
                    }

                    const assetType = getAssetContentTypeWithAssetFileFormat(
                      each.metadata?.assetFileFormat ?? "",
                    );

                    if (
                      assetType === "images" ||
                      assetType === "audios" ||
                      assetType === "videos"
                    ) {
                      return (
                        <GridImageItem
                          imageSrc={each.src}
                          key={each.extId}
                          navigateTo={routes.artwork(each.extId)}
                        />
                      );
                    }
                    // @TODO: Add support for videos on mansory view
                    else if (false) {
                      return (
                        <video
                          height="240"
                          key={each.extId}
                          preload="none"
                          ref={videoRef}
                          width="320"
                          onMouseOut={handleMouseOut}
                          onMouseOver={handleMouseOver}
                        >
                          <source
                            src="https://artifact-vault-assets-dev.s3.ap-southeast-1.amazonaws.com/T-HKPHIL/videos/6.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAWWU2GCYJ5CPSY25X%2F20240614%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20240614T060230Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAYaDmFwLXNvdXRoZWFzdC0xIkgwRgIhAM%2F29ueVfdPHTKrbms2YR1z2eXzVRdRG8ubV0r2MWqq%2BAiEApV2kUHxaqAeBdz7S39T8QT%2FOnY5Ca0QhXOAwc1oGErAqnwQIn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw0NjA5NTg2MDI3NzEiDHMHFqfbAhCVcAHPvCrzAywryysJmEA%2B0yZ44DDOpNvbEcBPYdlYkyUE4UsV9BoMUMUD8c13K%2B9v11hMN5Pyd29YcM2zdEz9eE95K06fsOzWFzh3HF6wgtK9mTvbvgS0VmQQCg%2FgBHk31itxuFAYuTmAdFzTXJO6W1WIt4uTyAEoLzp2H2HPhTnkcOevsvVxcpRVllfb9mSc7ePpbiszCqZgReLQ17kJ8Yz8IEdB6wN6%2FjJLjC0kAg2hodRWxYyjrkBQffKXOnZ9quxdAxh4DyRJG6TQVCOv%2BpZDCNi%2B0r1RzvIb3lTVUXZ6yi0bgp3OZCU9n1GsCW0JfeDaWxfZ4kE6vUNe02o1GsdrdhNSFPYmqHcoa5dkb75ob4HVCG4NH82w7%2FM7PJy%2FFHzfA0z9%2FWNj88zRYUp%2FoTdgP%2FEu9HuKZQo6Y%2FMHO41dkJX3g6JP%2FrvdQgHw8P35CkqjgQhGDyXthAoPcxKKC5GtXnjDXmJtLluN4thMq5bZ8R9HWUcccfJS0CzaXjNES9cwtHCKOXmPvl7x4%2BfT2L6%2FSBA3Ru%2FFK9Nlx8%2F8qEAx3jVMFqeq2IIaUYCEhaUFhpq%2BKMo0r7tkhuKayzr5Um5fblgrYQdCzJSg2yduz7dmfZCftHEboO5k5oBhJW4jNAzdz7zn32pEWL8e%2B0DjpWK4HqJAM4FA1VYw97GvswY6pQHZ7IVwYFL9A1oI3Y3jl5drN3i43B%2FSO3opaAvf%2FDQpqpUQ9guRMLwY%2B76vjY9IFucN2%2FCuvZIXEis7d1h4FR00TCIjXybu6u%2BYJw2pwy807ALwVX%2Bw1VyHBI5DOO3Czvrs3Cd9r4sTzsZgf5EehE6%2BLZe32En6xgfvfHWF8dzC2lmbS40SSplzza6AI46XBeIwB2rnf%2FnwPaefKpDIqtg2saLS3KU%3D&X-Amz-Signature=a8f2b579967a9f2966a34f20eefb4b5258fe2e0b5442bc5c2c77eca2f3a0adbe&X-Amz-SignedHeaders=host"
                            type="video/mp4"
                          />
                          {/* <track
                            kind="subtitles"
                            label="English"
                            src="/path/to/captions.vtt"
                            srcLang="en"
                          /> */}
                          Your browser does not support the video tag.
                        </video>
                        // <GridImageItem
                        //   imageSrc={each.thumbnail}
                        //   key={each.extId}
                        //   navigateTo={routes.artwork(each.extId)}
                        // />
                      );
                    }
                    //
                  })}
                </Masonry>
              </ResponsiveMasonry>

              <div className="flex items-center justify-center pb-[40px] pt-12">
                <AppLink
                  className="rounded-[4px] border-[1px] border-[#1C2442] bg-[#1C2442] px-4 py-[10px] hover:bg-[#35447d]"
                  href={routes.search(/*page*/ 1, "image", undefined, /*withDefaultParams*/ true)}
                >
                  <div className="font-poppins text-sm text-white">Explore</div>
                </AppLink>
              </div>
            </>
          )}

          {(assets?.paginatedResults ?? []).length === 0 && (
            <div className="flex items-center justify-center pb-[40px] pt-12">
              <div className="font-poppins text-sm">No results found</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
